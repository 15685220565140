export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const GET_USER = "GET_USER";
export const UPDATE_USER = "UPDATE_USER";

export const CHATS_LIST_GET = "CHATS_LIST_GET";
export const CHATS_LIST_CLEAN = "CHATS_LIST_CLEAN";
export const CHATS_LIST_UPDATE_ROW = "CHATS_LIST_UPDATE_ROW";

export const REVIEWS_LIST_GET = "REVIEWS_LIST_GET";
export const REVIEWS_LIST_CLEAN = "REVIEWS_LIST_CLEAN";
export const UPDATE_REVIEW = "UPDATE_REVIEW";

export const DOCTORS_LIST_GET = "DOCTORS_LIST_GET";
export const DOCTORS_LIST_CLEAN = "DOCTORS_LIST_CLEAN";

export const USERS_LIST_GET = "USERS_LIST_GET";
export const USERS_LIST_CLEAN = "USERS_LIST_CLEAN";

export const TRANSACTIONS_LIST_GET = "TRANSACTIONS_LIST_GET";
export const TRANSACTIONS_LIST_CLEAN = "TRANSACTIONS_LIST_CLEAN";

export const PROMOCODES_LIST_GET = "PROMOCODES_LIST_GET";
export const PROMOCODES_LIST_CLEAN = "PROMOCODES_LIST_CLEAN";
export const CREATE_PROMOCODE = "CREATE_PROMOCODE";
export const UPDATE_PROMOCODE = "UPDATE_PROMOCODE";
export const DELETE_RPMOCODE = "DELETE_RPMOCODE";

export const LOGS_LIST_GET = "LOGS_LIST_GET";
export const LOGS_LIST_CLEAN = "LOGS_LIST_CLEAN";

export const CLEAN_ON_UNMOUNT_TRUE = "CLEAN_ON_UNMOUNT_TRUE";
export const CLEAN_ON_UNMOUNT_FALSE = "CLEAN_ON_UNMOUNT_FALSE";

export const TOGGLE_SPINNER_INDICATOR = "TOGGLE_SPINNER_INDICATOR";

export const BOOTSTRAP_PUSH = "BOOTSTRAP_PUSH";

export const UPDATE_REQUESTS_COUNT = "UPDATE_REQUESTS_COUNT";

export const SUPPORT_LIST_GET = "SUPPORT_LIST_GET";
export const UPDATE_SUPPORT_COUNT = "UPDATE_SUPPORT_COUNT";
export const UPPORT_SUPPORT_COUNT = "UPPORT_LIST_CLEAN";

export const UPDATE_WITHDRAWAL_COUNT = "UPDATE_WITHDRAWAL_COUNT";
export const WITHDRAWAL_LIST_GET = "WITHDRAWAL_LIST_GET";
export const WITHDRAWAL_LIST_CLEAN = "WITHDRAWAL_LIST_CLEAN";
export const WITHDRAWAL_APPROVE_SUCCESS = "WITHDRAWAL_APPROVE_SUCCESS";

export const UPDATE_COUNCIL_COUNT = "UPDATE_COUNCIL_COUNT";
